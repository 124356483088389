<template>
  <b-modal
    id="modal-available-voucher"
    size="md"
    centered
    scrollable
    hide-footer
    @hidden="page = 1"
  >
    <template #modal-header>
      <fawesome-pro variant="far" icon="chevron-left" size="sm" class="align-self-center cursor-pointer pr-3" @click.native="page = 1" v-if="page == 2" /> 
      <h5 class="modal-title">{{ page == 1 ? "Ada voucher nih buat kamu" : "Detail Voucher" }}</h5>
      <button class="btn-close-modal-custom" @click="$bvModal.hide('modal-available-voucher')">&times;</button>
    </template>
    <!-- Page 1 : Available Voucher List -->
    <template v-if="page == 1">
      <div class="available-voucher-info">
        <fawesome-pro variant="far" icon="bell" size="sm" class="pr-2 available-voucher-info-logo" /> 
        <span>Voucher yang ada bisa kamu gunakan di halaman checkout</span>
      </div>

      <VoucherCard :voucher="voucher"  v-for="(voucher, indexVoucher) in vouchers" :key="indexVoucher" :showChevron="true" @click.native="onClickVoucherCard(voucher)" />
    </template>
    <!-- Page 2 : Detail Voucher -->
    <template v-else-if="page == 2">
      <VoucherCard class="cursor-auto" :voucher="selectedData" :isLoading="isLoadingVoucher" />
      <b-skeleton-wrapper :loading="isLoadingVoucher" class="mt-3">
        <template #loading>
          <b-skeleton width="100px" />
          <b-skeleton width="150px" class="mb-3" />

          <b-skeleton width="100px" />
          <b-skeleton width="150px" class="mb-3" />

          <b-skeleton width="100px" />
          <b-skeleton width="100%" v-for="index in 3" :key="index" />
        </template>
        <div class="voucher-detail-additional-info" v-if="selectedData">
          <template v-if="selectedData?.device_source">
            <b>Platform</b>
            <div>{{ selectedData.device_source }}</div>
          </template>

          <template v-if="selectedData?.min_pcs_qty > 0">
            <b>Min. Purchase Quantity</b>
            <div>{{ selectedData.min_pcs_qty }}</div>
          </template>

          <template v-if="selectedData?.accepted_payment">
            <b>Metode Pembayaran</b>
            <div>{{ selectedData.accepted_payment }}</div>
          </template>

          <template v-if="selectedData?.description">
            <b>Syarat & Ketentuan</b>
            <div v-html="selectedData.description"></div>
          </template>
        </div>
      </b-skeleton-wrapper>
    </template>
  </b-modal>
</template>

<script>
const VoucherCard = () => import("@/components/voucher/VoucherCard");

export default {
  name: "ModalAvailableVoucher",
  props: ["vouchers", "isCheckout"],
  components: {
    VoucherCard
  },
  data() {
    return {
      page: 1,
      selectedData: null,
    }
  },
  methods: {
    async getDetailVoucher(id) {
      try {
        this.isLoadingVoucher = true;
        const res = await this.$api.order.getDetailVoucher(id);
        if (res.status === 200) this.selectedData = res.data.data;
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoadingVoucher = false;
      } catch (error) {
        console.error(error);
      }
    },
    onClickVoucherCard(voucher) {
      //TODO handling untuk kasih border gold (select voucher)
      if (this.isCheckout) return
      else {
        this.page = 2;
        this.getDetailVoucher(voucher.id);
      }
    },
  },
  watch: {
    //* Set selected voucher data to null when go back to page 1 (available voucher list)
    page(val) {
      if (val == 1) this.selectedData = null;
    }
  }
}
</script>
<style scoped>
.available-voucher-info {
  display: flex;
  align-items: center;
  background: #FFF8E1;
  margin: 0px -15px .75rem;
  padding: .5rem 1rem;
}

@media only screen and (max-width: 992px) {
  .available-voucher-info {
    font-size: 14px;
  }
}
</style>